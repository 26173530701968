










































import Vue from 'vue'
import {Component} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {StoreNamespace} from "@/store/types";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {IShowGameAccountPopupData} from "@/components/GameAccounts/DisconnectGameAccountPopup.vue";
import ConnectGameAccountButton from "@/components/GameAccounts/ConnectGameAccountButton.vue";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components: {ConnectGameAccountButton, BaseButtonRounded}
})
export default class UserSettingsGameAccountsPage extends Vue {
  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private onDisconnectAccountAction (gameAccountId: number) {
    PopupsStoreModule.showPopup({
      popupType: PopupType.DisconnectGameAccount,
      data: {
        gameAccountId: gameAccountId
      } as IShowGameAccountPopupData
    })
  }

  private onAddNewAccount () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.ConnectGameAccount
    })
  }
}
